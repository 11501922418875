<style lang="scss">
@import './FooterStyle.scss';
</style>

<template>
  <footer id="main-footer">
    <slot name="section-partners"></slot>
    <div class="container">
      <div class="flex-wrapper">
        <div class="left">
          <div class="left-text">Join our newsletter to keep up to date with us!</div>
        </div>
        <div class="right">
          <div v-if="!showThankYou">
            <form class="right-newsletter--form form" @submit.prevent novalidate>
              <div class="field-group">
                <!--                <div class="label">E-mail address</div>-->
                <DJIcon class="icons-user" color="#545454" :size="20">
                  <IconEnvelope />
                </DJIcon>
                <input
                  type="text"
                  class="field"
                  v-model="subscribeEmail"
                  autocomplete="off"
                  @focus="handleFocus"
                  placeholder="Enter your email"
                  :style="errors.subscribeEmail ? 'border-color: #cb0303 !important;' : ''"
                />
                <div class="error-message" v-if="errors.subscribeEmail && $device.isMobile">
                  {{ errors.subscribeEmail }}
                </div>
              </div>

              <div class="form-submit w-100">
                <DJButton :loading="loading" type="accent" class="wp-100" title="Subscribe" aria-label="Subscribe" buttonNativeTypes="submit" @click="handleSubscribe" >Subscribe</DJButton>
              </div>
            </form>
            <div class="error-message" v-if="errors.subscribeEmail && $device.isDesktopOrTablet">
              {{ errors.subscribeEmail }}
            </div>
          </div>
          <div v-if="showThankYou" class="thanks-subscribe">
            <div class="title mb-10">Thanks for subscribing!</div>
            <div class="content">Please check your subscribe email inbox to confirm your newsletter subscription.</div>
          </div>
        </div>
      </div>
      <div class="flex-wrapper">
        <div class="left">
          <div class="left-logo">
            <router-link to="/" title="Go to Homepage" aria-label="Go to Homepage">
              <img v-if="$device.isMobile" :src="`${ASSETS_CDN}/images/general/logo_new.svg`" width="248" height="32" alt="Designer Journeys logo" />
              <img v-else :src="`${ASSETS_CDN}/images/general/logo_footer_new.svg`" width="205" height="27" alt="Designer Journeys logo" />
            </router-link>
          </div>

          <div class="left-newsletter">
            <div class="left-newsletter--title">Experience The World In Extraordinary Ways</div>
            <div class="left-social">
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/designerjourneys/"
                    title="Go to Linkedin"
                    aria-label="Go to Linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img :src="`${ASSETS_CDN}/images/general/linkedin_new.svg`" width="24" height="24" alt="Linked icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/DesignerJourneys"
                    title="Go to Facebook"
                    aria-label="Go to Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img :src="`${ASSETS_CDN}/images/general/facebook_new.svg`" width="24" height="24" alt="Facebook icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pinterest.com.au/designerjourneys/"
                    title="Go to Pinterest"
                    aria-label="Go to Pinterest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img :src="`${ASSETS_CDN}/images/general/pinterest_new.svg`" width="24" height="24" alt="Pinterest icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/designerjourneys_/"
                    title="Go to Instagram"
                    aria-label="Go to Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img :src="`${ASSETS_CDN}/images/general/instagram_new.svg`" width="24" height="24" alt="Instagram icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="right-column">
            <div class="item">
              <div class="item-title">Company</div>

              <div class="item-list">
                <ul>
                  <li v-for="(item, index) in navCompany" :key="`footer-navigation-company-${index}`">
                    <router-link :to="item.route" :title="`Go to page: ${item.name}`" :aria-label="`Go to page: ${item.name}`">
                      {{ item.name }}
                    </router-link>
                  </li>
                  <li>
                    <a target="_blank" :href="blogURL()" title="Go to page: Blog" aria-label="Go to page: Blog"> Blog </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/jobs/search/?f_C=18090474&geoId=92000000&position=1&pageNum=0"
                      target="_blank"
                      title="Go to page: Careers"
                      aria-label="Go to page: Careers"
                      rel="noopener noreferrer"
                    >
                      Careers
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right-column">
            <div class="item">
              <div class="item-title">For Local Designer</div>

              <div class="item-list">
                <ul>
                  <li>
                    <router-link to="/become-a-local-designer" title="Go to page: Become a Local Designer" aria-label="Go to page: Become a Local Designer">
                      Become a Local Designer
                    </router-link>
                  </li>
                  <li>
                    <a :href="toBecomeLocalDesigner" title="Go to page: Local Designer Login" aria-label="Go to page: Local Designer Login">
                      Local Designer Login
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right-column">
            <div class="item">
              <div class="item-title">For Travel Agent</div>

              <div class="item-list">
                <ul>
                  <li>
                    <router-link to="/private-group-tours" title="Go to page: Private Group Tours" aria-label="Go to page: Private Group Tours">
                      Private Group Tours
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/school-tour-details" title="Go to page: School Tours" aria-label="Go to page: School Tours">
                      School Tours
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/sign-up-travel-agent" title="Go to page: Become a Travel Agent" aria-label="Go to page: Become a Travel Agent">
                      Become a Travel Agent
                    </router-link>
                  </li>
                  <li>
                    <a :href="url" title="Go to page: Travel Agent Login" aria-label="Go to page: Travel Agent Login"> Travel Agent Login </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right-column">
            <div class="item">
              <div class="item-title">Policy</div>

              <div class="item-list">
                <ul>
                  <li v-for="(item, index) in navTerms" :key="`footer-navigation-terms-${index}`">
                    <router-link :to="item.route" :title="`Go to page: ${item.name}`" :aria-label="`Go to page: ${item.name}`">
                      {{ item.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-wrapper">
        <div class="item">
          <div class="item-title">Payment Methods</div>
          <div class="list-item">
            <ul>
              <li>
                <img :src="`${ASSETS_CDN}/images/general/paypal_new.svg`" width="88" height="32" alt="Image - Designer Journeys" />
              </li>

              <li>
                <img :src="`${ASSETS_CDN}/images/general/visa_new.svg`" width="62" height="32" alt="Image - Designer Journeys" />
              </li>

              <li>
                <img :src="`${ASSETS_CDN}/images/general/master_card_new.svg`" width="28" height="32" alt="Image - Designer Journeys" />
              </li>
            </ul>
          </div>
        </div>
        <div class="item">
          <div class="item-title">Certificate and Security</div>
          <div class="list-item">
            <ul>
              <li>
                <img :src="`${ASSETS_CDN}/images/general/footer-certificate.svg`" width="125" height="32" alt="Image - Designer Journeys" />
              </li>

              <li>
                <img :src="`${ASSETS_CDN}/images/general/google_new.svg`" width="74" height="32" alt="Image - Designer Journeys" />
              </li>
            </ul>
          </div>
        </div>
        <div class="item">
          <div class="item-title">Accredited by</div>
          <div class="list-item">
            <ul>
              <li>
                <img :src="`${ASSETS_CDN}/images/general/atas_new.svg`" width="32" height="32" alt="Image - Designer Journeys" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="copyright">
          © {{ getCurrentYear }} <a :href="goToDJ"><span class="brand-400 hand">Designer Journeys</span></a
          >™. All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, computed } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { load } from 'recaptcha-v3'
import useFormValidation from '~/utils/validators'
import DJButton from '~/components/ui/button/Button'
import DJIcon from '~/components/ui/icon/Icon.vue'
import { IconEnvelope } from '~/components/ui/icon/icons'
import { useGetCookie } from '~/composables/common/useCustomCookie'
import { debounceHeapTracking } from '~/mixins/heap'
import { debounceAmplitudeScriptTracking } from '~/mixins/amplitude'
import { assetCDN } from '~/helper/index.js'
import { useSubscribeEmail } from '~/store/pinia/subscribeEmail'

// ---Define---
const navTerms = [
  {
    name: 'Terms of Use',
    route: '/terms-of-use',
  },
  {
    name: 'Privacy Policy',
    route: '/privacy-policy',
  },
  {
    name: 'Data Protection',
    route: '/data-protection',
  },
  {
    name: 'Cookie Declaration',
    route: '/cookie-declaration',
  },
  {
    name: 'Traveller Protections',
    route: '/traveller-protections',
  },
]

const navCompany = [
  {
    name: 'About Us',
    route: '/about-us',
  },
  {
    name: 'Contact Us',
    route: '/contact-us',
  },
  {
    name: 'How it Works',
    route: '/how-it-works',
  },
]

// const navLocalSupplier = [
//   {
//     name: 'Become a Local Designer',
//     route: '/become-a-local-designer',
//   },
//   {
//     name: 'Local Designer Login',
//     route: '#',
//   },
// ]
//
// const navTravelAgent = [
//   {
//     name: 'Become a Travel Agent',
//     route: '/sign-up-travel-agent',
//   },
//   {
//     name: 'Private Group Tours',
//     route: '/private-group-tours',
//   },
//   {
//     name: 'Travel Agent Login',
//     route: useAppConfig().publicDomainAgent,
//   },
// ]

const { validateEmailFieldFooter, errors } = useFormValidation()
const subscribeEmail = ref('')
// const recaptchaKey = ref('')
const loading = ref(false)
// const disabled = ref(true)
const showThankYou = ref(false)
const ASSETS_CDN = assetCDN()
const subcribeEmailStore = useSubscribeEmail()

// ---Computed---
const getCurrentYear = computed(() => new Date().getFullYear())
const url = computed(() => {
  return useAppConfig().publicDomainAgent
})
const toBecomeLocalDesigner = computed(() => {
  return useAppConfig().adminDomain
})
const goToDJ = computed(() => {
  return useRuntimeConfig().public.publicDomain
})
const blogURL = () => {
  return `${useRuntimeConfig().public.publicDomain}/blog/`
}

// ---BeforeRouteLeave---
onBeforeRouteLeave(async () => {
  errors.subscribeEmail = ''
  // disabled.value = true
})

// ---Method---
// const handleFocus = async () => {
//   disabled.value = false
//   /* Load recaptcha */
//   const recaptcha = await load(`${process.env.VUE_APP_SITE_KEY_GRECAPTCHA}`)
//   const token = await recaptcha.execute('subscribe')
//   recaptchaKey.value = token
// }

const handleSubscribe = async () => {
  const country_code = useGetCookie("country_code").value
  const country_name = useGetCookie("country_name").value

  /* Validate valid subscribeEmail */
  validateEmailFieldFooter('subscribeEmail', subscribeEmail.value)
  if (!errors.subscribeEmail) {
    /* Load recaptcha */
    const recaptcha = await load(`${useAppConfig().siteKeyGreCaptcha}`)
    const token = await recaptcha.execute('subscribe')
    // recaptchaKey.value = token
    // const data = {
    //   email: subscribeEmail.value,
    //   country_code: getCookie('country_code=') || null,
    //   country_name: getCookie('country_name=') || null,
    //   client_ip: window.client_addr || '',
    //   'g-recaptcha-response': token,
    // }
    const data = {
      email: subscribeEmail.value,
      country_code: country_code || null,
      country_name: country_name || null,
      client_ip: window.client_addr || '',
      'g-recaptcha-response': token,
    }
    loading.value = true
    try {
      await subcribeEmailStore.subscribeEmail(data)
      const subscribeResponse = computed(() => subcribeEmailStore.subscribeData)
      if (subscribeResponse.value.error === 0) {
        showThankYou.value = true
        loading.value = false
        setTimeout(function () {
          subscribeEmail.value = ''
          showThankYou.value = false
        }, 5000)
      } else {
        errors.subscribeEmail = subscribeResponse.value.message
        loading.value = false
      }
    } catch (err) {
      console.log(err)
    } finally {
      debounceHeapTracking('Subscribe')
      debounceAmplitudeScriptTracking('Subscribe')
      loading.value = false
    }
  } else {
    return false
  }
}
</script>
